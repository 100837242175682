import {
  Accordion,
  AccordionLabel,
  AccordionContent,
  IAccordionItem,
  Link,
} from '@prescriberpoint/ui';
import {ShieldCheck} from 'lucide-react';
import dynamic from 'next/dynamic';
import {FC, useMemo, useState, memo} from 'react';
import {getTitleFromLabel} from './utils';
import {DDIButton} from '@/components';
import PdpHeaderV1 from '@/components/PdpHeaderV1';
import * as FLAGS from '@/constants/flags';
import {OTCLabelKey, OTC_LABEL_ORDER} from '@/constants/labelInformation';
import {useFlag} from '@/context';
import {useCurrentDrug, useCurrentSlug} from '@/hooks';
import {useLabel} from '@/queries';
import {removeAllHTMLElementInStr} from '@/utils/string';

const ReportAdverseEvent = dynamic(() => import('./ReportAdverseEvent'));

const MIN_ACCORDION_LENGTH = 8;

const OTCProductDetail: FC = () => {
  const {setId} = useCurrentSlug();
  const {drugName, genericName} = useCurrentDrug();
  const {data: product} = useLabel(setId);

  const showRAE = useFlag(FLAGS.REPORT_ADVERSE_EVENT_SECTION);
  const showDDI = useFlag(FLAGS.DDI_TOOL);

  const [accordionLength, setAccordionLength] = useState(MIN_ACCORDION_LENGTH);

  const labelKeys = useMemo(
    () =>
      Object.keys(product?.label || {}).filter(
        // @ts-ignore
        (key) => product?.label[key],
      ),
    [product],
  ) as OTCLabelKey[];

  const sortedHightlights = useMemo(() => {
    const sorted: OTCLabelKey[] = [];
    labelKeys.forEach((section) => {
      //@ts-ignore
      if (product?.label[section]) {
        sorted[OTC_LABEL_ORDER[section]] = section;
      }
    });
    const labelSections = sorted.filter((section) => section);
    return labelSections;
  }, [product, labelKeys]);

  const higlightAccordionItems: IAccordionItem[] = useMemo(() => {
    const highlights = sortedHightlights
      .slice(0, accordionLength)
      .map((label) => {
        const isBlackBoxWarning = label === 'boxedWarning';
        const isWarning = label === 'warnings';
        const content = product?.label[label];
        return {
          label: (props) => (
            <AccordionLabel label={getTitleFromLabel(label)} {...props} />
          ),
          content: () => {
            if (label === 'contraindications' && showDDI) {
              return (
                <div className='flex flex-col bg-neutral-lighter-alt pt-2'>
                  <div className='px-4'>
                    <DDIButton id='check_interactions_contraindications' />
                  </div>
                  <AccordionContent
                    content={removeAllHTMLElementInStr(content ?? '', 'h1')}
                  />
                </div>
              );
            }
            return (
              <AccordionContent
                content={removeAllHTMLElementInStr(content ?? '', 'h1')}
                clampable={isWarning}
              />
            );
          },
          className: isBlackBoxWarning
            ? 'border border-solid border-black'
            : '',
        };
      }) as IAccordionItem[];
    return highlights.concat([
      {
        label: (props) => (
          <AccordionLabel
            label='Data Source'
            LeftIcon={ShieldCheck}
            {...props}
          />
        ),
        content: () => (
          <AccordionContent
            content='We receive information directly from the FDA and PrescriberPoint is updated as frequently as change are made available'
            contentClassName='md:w-3/5'
          />
        ),
      },
    ]);
  }, [accordionLength, product, sortedHightlights, showDDI]);

  const isAccordionCollapsed = accordionLength === MIN_ACCORDION_LENGTH;

  return (
    <div className='w-full animate-fadeIn'>
      <div className='relative mx-auto max-w-[1440px] bg-white md:mb-4 md:py-6'>
        <div className='flex flex-col space-y-4 rounded-lg bg-neutral-lighter-alt p-4'>
          <PdpHeaderV1 drugName={drugName} genericName={genericName} />
        </div>
      </div>
      <div className='relative mx-auto max-w-[1440px] bg-white md:mb-4 md:px-6 md:py-6'>
        <div className='flex flex-col space-y-4 rounded-lg bg-neutral-lighter-alt p-4'>
          <div className='flex flex-col space-y-4'>
            <Accordion
              className='rounded-lg shadow-lg'
              id='otc'
              accordionItems={higlightAccordionItems}
            />
            <div className='flex justify-end'>
              {higlightAccordionItems?.length > MIN_ACCORDION_LENGTH ? (
                <Link
                  id='view_full_label_button'
                  data-testid='view_full_label_button'
                  className='bg-white'
                  onClick={() =>
                    setAccordionLength(
                      isAccordionCollapsed
                        ? labelKeys?.length
                        : MIN_ACCORDION_LENGTH,
                    )
                  }
                  label={isAccordionCollapsed ? `View more` : 'View less'}
                />
              ) : null}
            </div>
          </div>
          {showRAE ? <ReportAdverseEvent /> : null}
        </div>
      </div>
      {/** We added the footer to add universal pixel in OTC */}
      <footer />
    </div>
  );
};

export default memo(OTCProductDetail);
